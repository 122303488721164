<template>
    <div class="modal fade" id="addclient" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter client</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group">
              <label for="namec" class="weight">Nom Complete</label>
              <input type="text" id="namec" @keyup="checker" v-model="send.name" class="form-control" placeholder="Nom Complete">
          </div>
           <div class="form-group">
              <label for="phonec" class="weight">Mobile</label>
              <input type="number" id="phonec" @keyup="checker" v-model="send.phone" class="form-control" placeholder="Mobile">
          </div>
           <div class="form-group">
              <label for="emailc" class="weight">Email</label>
              <input type="email" id="emailc" @keyup="checker" v-model="send.email" class="form-control" placeholder="Email">
          </div>
           <div class="form-group">
              <label for="pinc" class="weight">Pin</label>
              <input type="number" id="pinc" @keyup="checker" v-model="send.pin" class="form-control" placeholder="Pin">
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" @click="add" :disabled="dis" class="btn btn-primary">Ajouter</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data : ()=>{
        return{
            dis : true,
            send : {
                name : null,
                phone : null,
                email : null,
                pin : null
            }
        }
    },
    methods : {
        checker()
        {
            this.dis = (this.send.name == null || this.send.phone == null || this.send.email == null 
            || this.send.pin == null) ? true: false
        },
        add()
        {
            var run = axios.post(this.link() + 'addclients',this.send)
            run.then(e=>{
                if(e.data == "yes")
                {
                    this.statu('Opèration èffectuè','success')
                    location.reload()
                }else if(e.data == "no")
                {
                    this.statu('Mobile dèja utilisè','error')
                }
            })
            run.catch(e=>{
                console.log('err')
            })
        }
    },
}
</script>
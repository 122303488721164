<template>
    <div class="modal fade" id="checkdevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="!view">
          <div class="form-group">
            <label for="pr" class="weight">Prix</label>
            <input type="text"  id="pr" @keyup="checker" v-model="send.price" class="form-control" placeholder="ex : 1000">
        </div>

        <div class="form-group">
            <label for="comment" class="weight">Note</label>
            <textarea v-model="send.comment" @keyup="checker" class="form-control" placeholder="Commentaire" id="comment"></textarea>
        </div>
        </div>
        <div v-if="view">
          <div v-for="(t,index) in tmp" :key="index" id='ticket' style='display: flex;width: 450px;padding: 20px;flex-direction: column;align-items: center;'>
            <p style='display: flex;flex-direction: column;' class="center"><span class='weight'><h3>IT-SMV INFORMATIQUE</h3></span>
                <span style="font-size: 12px;" class='center'>Maintenance - dèpannage et Rèparation</span>  </p>
                <span style="font-size: 12px;" class='center'>Ordinateur protables/Pcs/Imprimantes/Serveurs/Onduleurs</span>
                <span class='center weight'><h4>ORDRE sortie</h4></span>
                <span class='size'> <span>BON N : </span> {{t.ref_bon}}</span>
                <p > <span class='weight'>Client : </span> {{t.name_client}} </p>
                <p > <span class='weight'>Produit : </span> {{t.mark}} </p>
                <p > <span class='weight'>Commentaire : </span>
                 </p>
                 <p><span v-if="t.comment == null">/</span>
                <span v-else>{{t.comment}}</span></p>
                <p v-if="t.price != null" > <span class='weight'>Prix : </span> {{t.price}}.00 DA</p>
                <div style='display: flex;flex-direction: column;' class="weight">
                   <barcode :value="t.ref_bon"></barcode>
                </div>
                <span class="center">{{contacts.split(';')[0]}}</span>
                    <span class='center'>{{contacts.split(';')[1]}}</span>
                    <span class="center">{{contacts.split(';')[2]}}</span>

        </div>
        </div>
        <div v-if="view"> <button v-print="'#ticket'" v-if="view" class="btn btn-primary form-control"><ic icon="print" /></button></div>
      </div>
      <div class="modal-footer">
        <button type="button" @click="refresh" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" :disabled="dis" @click="check" class="btn btn-success"><ic icon="check" /></button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import print from 'vue-print-nb'
import VueBarcode from 'vue-barcode'
export default {
    props : ['dv','contacts'],
    data : ()=>{
        return{
          view : false,
          dis : true,
            send : {
                id : null,
                price : null,
                comment : null,
            },
            tmp : []
        }
    },
    components : {
      'barcode': VueBarcode,
    },
    directives : {
      print
    },
    methods : {
        check()
        {
          this.dis = true
          this.send.id = this.dv.id_device
          axios.post(this.link() + 'validdevice',this.send)
          .then(e=>{
            this.view = true
            this.tmp = e.data
          })
          .catch(e=>{
            console.log('err')
          });
        },
        checker()
        {
          this.dis = (this.send.price == null || this.send.comment == null) ? true : false
        },
        refresh()
        {
          location.reload()
        }
    }

}
</script>
<template>
    <div class="main">
        <client-comp v-if="type == 0" />
        <user-comp v-if="type == 1" />
        <footer-comp v-if="type == 0" />
    </div>
</template>

<script>
import clientComp from '../components/clientComp'
import userComp from  '../components/userComp'
import footerComp from '../components/footerComp'
export default {
    props : ['type'],
    components : {
        clientComp,
        userComp,
        footerComp
    }
}
</script>
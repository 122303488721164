<template>
    <div class="users default1">
        <div class="card">
            <div class="card-header">
                <button data-toggle="modal" data-target="#addtp" class="btn btn-primary"><ic icon="plus" /></button>
            </div>
            <div class="card-body">
<table class="table table-striped" id="cmdd">
  <thead>
    <tr>
      <th scope="col">Type</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="(type,index) in types" :key="index">
          <td>{{type.name_type}}
              <button data-toggle="modal" @click="handle(type)" data-target="#uptp" class="btn btn-success"><ic icon="pen" /></button>
              <button data-toggle="modal" @click="handle(type)" data-target="#deltp" class="btn btn-danger"><ic icon="trash" /></button>
          </td>
      </tr>
  </tbody>
</table>                
            </div>
        </div>
        <addtp-modal />
        <edittp-modal :type='type' />
        <deltp-modal :type='type' />
    </div>
</template>

<script>
import datatable from 'datatables.net-bs4'
import axios from 'axios'
import addtpModal from '../modals/addtpModal'
import edittpModal from '../modals/edittpModal'
import deltpModal from '../modals/deltpModal'
export default {
    data : ()=>{
        return{
            types : [],
            type : []
        }
    },
    components : {
        addtpModal,
        edittpModal,
        deltpModal
    },
    methods : {
         table()
          {
              this.$nextTick(()=>{
                  $('#cmdd').DataTable();
              })
          },
          handle(value)
          {
              this.type = value
          }
    },
    created()
    {
        var run = axios.get(this.link() + 'types')
        run.then(e=>{
            this.types = e.data
            this.table()
        })
        run.catch(e=>{
            console.log('err')
        })
    }
}
</script>
<template>
    <div class="modal fade" id="codesmodals" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Materiels</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-for="(code,index) in codes" :key="index" style="text-align:center;" class="form-group">
            <barcode :value="code"></barcode>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VueBarcode from 'vue-barcode'
export default {
    props : ['codes'],
    components : {
        'barcode': VueBarcode,
    }
}
</script>
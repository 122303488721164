<template>
    <footer style='position: relative;bottom: 0;top: 26px;display: block;'>
       <div style='background: #282b31;color: white;padding:20px;' class="card">
           <div class="container">
               <div class="row">
                   <div class="col-lg-4 col-sm-12 col-xm-12"><p><ic icon="mail-bulk" /> {{contacts[0]}}</p></div>
                   <div class="col-lg-4 col-sm-12 col-xm-12"><p><ic icon="phone" /> {{contacts[1]}}</p></div>
                   <div class="col-lg-4 col-sm-12 col-xm-12"><p><ic icon="address-card" /> {{contacts[2]}}</p></div>
               </div>
           </div>
       </div>
    </footer>
</template>

<script>
import axios from 'axios'
export default {
    data : ()=>{
        return{
            contacts : [],
        }
    },
    async created()
    {
        var run =  axios.get(this.link() + 'contacts');
     await run.then(e=>{
          this.contacts = e.data.split(';')
      })
      run.catch(e=>{
          console.log('err')
      })
    }
}
</script>
<template>
    <div  class="users defaulta">
        <div class="card">
            <div class="card-header">
                <button data-toggle="modal" data-target="#adddevice" class="btn btn-primary"><ic icon="plus" /></button>
            </div>
            <div class="card-body">
<table class="table table-striped" id="cmdss">
  <thead>
    <tr>
      <th scope="col">Nom Complete client</th>
      <th scope="col">Numèro de bon</th>
      <th scope="col">Marquee</th>
      <th scope="col">Type</th>
      <th scope="col">codeBar</th>
      <th scope="col">Statu</th>
      <th scope="col">Prix</th>
      <th scope="col">lieu</th>
      <th scope="col">Date entrèe</th>
      <th scope="col">Date sortie</th>
      <th scope="col">Materiels</th>
      <th scope="col">Autre</th>
      <th scope="col">Bon sortie</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="(dv,index) in dvs" :key="index">
          <td>{{dv.name_client}}
              <button data-toggle="modal" @click="handle(dv)" data-target="#updevice" class="btn btn-success"><ic icon="pen" /></button>
              <button data-toggle="modal" @click="handle(dv)" data-target="#deldevice" class="btn btn-danger"><ic icon="trash" /></button>
          </td>
          <td>{{dv.ref_bon}}</td>
          <td>{{dv.mark}}</td>
          <td>{{dv.name_type}}</td>
          <td><barcode :value="dv.ref_bon"></barcode></td>
          <td> <span v-if="dv.statu == 0" class="badge badge-warning">En attente </span> <span v-if="dv.statu == 1" class="badge badge-warning">En cours</span> <span v-if="dv.statu == 2" class="badge badge-success">rèparè</span> <span v-if="dv.statu == 3" class="badge badge-danger">Non rèparè</span> 
          <button v-if="dv.statu == 0" @click="start(dv)"  class="btn btn-warning"><ic icon="power-off" /></button>
           <button v-if="dv.statu == 1" data-toggle="modal" @click="handle(dv)" data-target="#checkdevice" class="btn btn-success"><ic icon="check" /></button>
           <button v-if="dv.statu == 1" data-toggle="modal" @click="handle(dv)" data-target="#timesdevice" class="btn btn-danger"><ic icon="times" /></button>
            </td>
            <td>{{dv.price}}.00 DA</td>
            <td> <span v-if="dv.location == 0">à domicile</span> <span v-if="dv.location == 1">Atelier</span> </td>
          <td>{{dv.date_entrer}}</td>
          <td>{{dv.date_out}}</td>
          <td><button data-toggle="modal" data-target="#codesmodals" @click="format(dv)" class="btn btn-warning"><ic icon="boxes" /></button></td>
          <td>{{dv.other}}</td>
          <td><button v-if="dv.statu == 2" data-toggle="modal" data-target="#bonmodal" @click="format1(dv.ref_bon)" class="btn btn-warning"><ic icon="file-invoice" /></button></td>
      </tr>
  </tbody>
</table>                
            </div>
        </div>
        <adddevice-modal :contacts="contacts" />
        <editdevice-modal :dv="dv" />
        <deldevice-modal :dv="dv" />
        <validdevice-modal :contacts="contacts" :dv="dv" />
        <timesdevice-modal :dv="dv" />
        <codes-modal v-if="codes != null" :codes="codes" />
        <bon-modal :contacts="contacts" :infos="infos" />
    </div>
</template>

<script>
import datatable from 'datatables.net-bs4'
import axios from 'axios'
import VueBarcode from 'vue-barcode'
import adddeviceModal from '../modals/adddeviceModal'
import editdeviceModal from '../modals/editdeviceModal'
import deldeviceModal from '../modals/deldeviceModal'
import validdeviceModal from '../modals/validdeviceModal'
import timesdeviceModal from '../modals/timesdeviceModal'
import codesModal from '../modals/codesModal'
import bonModal from '../modals/bonModal'
export default {
    data : ()=>{
        return{
            dvs : [],
            dv : [],
            codes : null,
            infos : [],
            contacts : null,
        }
    },
    components : {
            'barcode': VueBarcode,
            adddeviceModal,
            editdeviceModal,
            deldeviceModal,
            validdeviceModal,
            timesdeviceModal,
            codesModal,
            bonModal
    },
    methods : {
         table()
          {
              this.$nextTick(()=>{
                  $('#cmdss').DataTable();
              })
          },
          start(value)
          {
              var run = axios.post(this.link() + 'start',value)
              run.then(e=>{
                  if(e.data == "yes")
                  {
                      this.statu('Opèration èffectuè','success')
                      location.reload()
                  }
              })
              run.catch(e=>{
                  console.log('err')
              })
          },
          handle(value)
          {
              this.dv = value
          },
          format(value)
          {
              this.codes = value.barcode.split(';')
          },
          async format1(value)
          {
              let run = axios.post(this.link() + 'getbybon',{ref : value})
             await run.then(e=>{
                  this.infos = e.data
              })
              run.catch(e=>{
                  console.log('err')
              });
          }
    },
    created()
    {
        var run = axios.get(this.link() + 'devices')
        run.then(e=>{
            this.dvs = e.data
            this.table()
        })
        run.catch(e=>{
            console.log('err')
        });

        var run2 = axios.get(this.link() + 'contacts')
        run2.then(e=>{
            this.contacts = e.data
        })
        run2.catch(e=>{
            console.log('err')
        })
    }
}
</script>
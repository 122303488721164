<template>
    <div class="users default1">
        <div class="card">
            <div class="card-header">
                <button data-toggle="modal" data-target="#adduser" class="btn btn-primary"><ic icon="plus" /></button>
            </div>
            <div class="card-body">
<table class="table table-striped" id="cmd">
  <thead>
    <tr>
      <th scope="col">Nom Complete</th>
      <th scope="col">Mobile</th>
      <th scope="col">Pin</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="(user,index) in users" :key="index">
          <td>{{user.username_user}}
              <button data-toggle="modal" @click="handle(user)" data-target="#deluser" class="btn btn-danger"><ic icon="trash" /></button>
          </td>
          <td>{{user.phone_user}}</td>
          <td>{{user.pin_user}}</td>
      </tr>
  </tbody>
</table>                
            </div>
        </div>
        <adduser-modal />
        <deluser-modal :user="user" />
    </div>
</template>

<script>
import datatable from 'datatables.net-bs4'
import axios from 'axios'
import adduserModal from '../modals/adduserModal'
import deluserModal from '../modals/deluserModal'
export default {
    data : ()=>{
        return{
            users : [],
            user : null
        }
    },
    components : {
        adduserModal,
        deluserModal
    },
    methods : {
         table()
          {
              this.$nextTick(()=>{
                  $('#cmd').DataTable();
              })
          },
          handle(value)
          {
              this.user = value
          }
    },
    created()
    {
        var run = axios.post(this.link() + 'users',{id : localStorage.getItem('muserid')})
        run.then(e=>{
            this.users = e.data
            this.table()
        })
        run.catch(e=>{
            console.log('err')
        })
    }
}
</script>
<template>
    <div class="modal fade" id="adddevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter materiel</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="!view">
            <div class="form-group">
            <label for="client" class="weight">Clients</label>
           <select  id="client" @change="checker" class="form-control" v-model="send.id">
               <option v-for="(client,index) in clients" :key="index" :value="client.id_client">{{client.name_client}}</option>
           </select>
        </div>
        <div class="form-group">
            <label for="mark" class="weight">Marquee</label>
           <input type="text" @keyup="checker" id="mark" placeholder="Marquee" class="form-control" v-model="send.mark">
        </div>
        <div class="form-group">
            <label for="types" class="weight">Types</label>
           <select  id="types" @change="checker" class="form-control" v-model="send.type">
               <option v-for="(type,index) in types" :key="index" :value="type.id_type">{{type.name_type}}</option>
           </select>
        </div>
        <div class="form-group">
            <label for="loc" class="weight">Lieu</label>
           <select  id="loc" @change="checker" class="form-control" v-model="send.location">
               <option selected disabled>Choisir</option>
               <option value="0">à domicile</option>
               <option value="1">Atelier</option>
           </select>
        </div>
        <div class="form-group">
            <label for="other" class="weight">Autre</label>
            <textarea id="other" class="form-control" v-model="send.other" ></textarea>
        </div>
        <div class="form-group">
            <label for="code" class="weight">Code bar</label>
            <input type="text" id="code" class="form-control" placeholder="Code bar" @change="onScan($event)">
        </div>
        <div v-if="send.codebar.length > 0" v-for="(code,index) in send.codebar" :key="index" class="form-group">
            <barcode :value="code"></barcode>
        </div>
        </div>
        <div v-if="view">
            <div v-for="(t,index) in tmp" :key="index" id='ticket' style='display: flex;width: 450px;padding: 20px;flex-direction: column;align-items: center;'>
            <p style='display: flex;flex-direction: column;' class="center"><span class='weight'><h3>IT-SMV INFORMATIQUE</h3></span>
                <span style="font-size: 12px;" class='center'>Maintenance - dèpannage et Rèparation</span>  </p>
                <span style="font-size: 12px;" class='center'>Ordinateur protables/Pcs/Imprimantes/Serveurs/Onduleurs</span>
                <span class='center weight'><h4>ORDRE RÉPARATION</h4></span>
                <span class='size'> <span>BON N : </span> {{t.ref_bon}}</span>
                <p > <span class='weight'>Client : </span> {{t.name_client}} </p>
                <p > <span class='weight'>Produit : </span> {{t.mark}} </p>
                <p > <span class='weight'>Commentaire : </span>
                 </p>
                 <p><span v-if="t.other == null">/</span>
                <span v-else>{{t.other}}</span></p>
                <div style='display: flex;flex-direction: column;' class="weight">
                   <barcode :value="t.ref_bon"></barcode>
                </div>
                <span class="center">{{contacts.split(';')[0]}}</span>
                    <span class='center'>{{contacts.split(';')[1]}}</span>
                    <span class="center">{{contacts.split(';')[2]}}</span>

        </div>
        </div>
            <button v-print="'#ticket'" v-if="view" class="btn btn-primary form-control"><ic icon="print" /></button>
      </div>
      <div class="modal-footer">
        <button type="button" @click="close" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" @click="add" :disabled="dis" class="btn btn-primary">Ajouter</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import VueBarcode from 'vue-barcode'
import print from 'vue-print-nb'

export default {
    props : ['contacts'],
    data : ()=>{
        return{
            view : false,
            send : {
                id : null,
                mark : null,
                type : null,
                codebar : [],
                location : null,
                other : null,
            },
            clients : [],
            types : [],
            dis : true,
            tmp : []
        }
    },
    directives : {
        print
    },
    components : {
        'barcode': VueBarcode,
    },
    methods : {
        add()
        {
            this.dis = true
          var run = axios.post(this.link() + 'adddevice',this.send)
          run.then(e=>{
              this.view = true
              this.tmp = e.data
          })
          run.catch(e=>{
              console.log('err')
          })   
        },
        close()
        {
            location.reload()
        },
        checker()
        {
            this.dis = (this.send.id == null || this.send.mark == null || this.send.type == null
             || this.send.location == null) ? true : false
        },
        onScan(value)
        {
            this.send.codebar.push(value.target.value)
            this.rmv()

            this.dis = (this.send.id == null || this.send.mark == null || this.send.type == null
           || this.send.location == null) ? true : false
        },
        onScan1(value)
        {
            this.send.codebar.push(value)
            this.rmv()
            this.dis = (this.send.id == null || this.send.mark == null || this.send.type == null
            || this.send.location == null) ? true : false
        },
        rmv()
        {
            this.send.codebar = [... new Set(this.send.codebar)]
        }
    },
    created()
    {
        axios.get(this.link() + 'clients')
       .then(e=>{
           this.clients = e.data
       })
       .catch(e=>{
           console.log('err')
       });
        axios.get(this.link() + 'types')
       .then(e=>{
           this.types = e.data
       })
       .catch(e=>{
           console.log('err')
       });

        this.$barcodeScanner.init(this.onScan1)
    }
}
</script>
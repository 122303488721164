<template>
<div class="wrap">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="informations center">
                    <img src="logo.jpeg" alt="logo">
                    <p style='display: flex;flex-direction: column;' class="weight"><span>Soyez les bienvenu</span> <span>{{username}}</span></p>
                    <div v-if="prev[0] != ''" style='margin-top:6px;' class="alert alert-success">
                        <ol class="fuck">
                            <li v-for="(p,index) in prev" :key="index">{{p}}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="client defaulta">
        <div class="card">
            <button @click="dec" class="btn btn-danger"><ic icon="power-off" /></button>
            <button @click="refresh" class="btn btn-warning"><ic icon="recycle" /></button>
            <div class="card-body">
                <div v-if="!view">
                    <div class="form-group">
                    <input type="text" @keyup="checker" v-model="send.ref" placeholder="N# bon" class="form-control">
                </div>
                <div class="form-group">
                    <button @click="suivi" :disabled="dis" class="btn btn-primary form-control">SUIVI</button>
                </div>
                </div>
                <div v-if="view">
                    <table class="table">
                        <thead  class='sp' style="background:#babaff;">
                            <tr>
                                <th>N# bon</th>
                                <th>Statu</th>
                                <th>Prix</th>
                                <th>Date entrèe</th>
                                <th>Date sortie</th>
                                <th>Note</th>
                                <th>Bon</th>
                                <th>Materiels</th>
                            </tr>
                        </thead>
                        <tbody class="sp1">
                            <tr v-for="(info,index) in infos" :key="index">
                                <td>{{info.ref_bon}}</td>
                                <td><span v-if="info.statu == 0" class="badge badge-warning">En attente </span> <span v-if="info.statu == 1" class="badge badge-warning">En cours</span>
                                 <span v-if="info.statu == 2" class="badge badge-success">rèparè
                                 </span>
                                  <span v-if="info.statu == 3" class="badge badge-danger">Non rèparè
                                      </span></td>
                                <td><span v-if="info.price != null">{{info.price}}.00 DA</span></td>
                                <td>{{info.date_entrer}}</td>
                                <td>{{info.date_out}} </td>
                                <td> <span v-if="info.statu == 2 || info.statu == 3">{{info.comment}}</span> </td>
                                <td><button data-toggle="modal" data-target="#bonmodal" class="btn btn-warning"><ic icon="file-invoice" /></button></td>
                                <td><button data-toggle="modal" data-target="#codesmodals" @click="format(info)" class="btn btn-warning"><ic icon="boxes" /></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <bon-modal :infos="infos" />
        <codes-modal v-if="codes != null" :codes="codes" />
    </div>
</div>
</template>

<script>
import axios from 'axios'
import codesModal from '../modals/codesModal'
import bonModal from '../modals/bonModal'
export default {
    data : ()=>{
        return{
            dis : true,
            view : false,
            send : {
                ref : null,
            },
            infos : [],
            codes : null,
            prev : [],
            id : localStorage.getItem('muserid'),
            username : null,
        }
    },
    components : {
        bonModal,
        codesModal
    },
    methods : {
        suivi()
        {
            axios.post(this.link() + 'getbybon',this.send)
            .then(e=>{
                this.view = true
                this.infos = e.data
            })
            .catch(e=>{
                console.log('err')
            });
        },
        refresh()
        {
            location.reload()
        },
        checker()
        {
            this.dis = (this.send.ref == null) ? true : false
        },
        dec()
    {
      localStorage.removeItem('muserid')
      localStorage.removeItem('type')
      location.reload()
    },
    format(value)
          {
              this.codes = value.barcode.split(';')
          }
    },
    async created()
    {
      var run = axios.get(this.link() + 'infos');
      await run.then(e=>{
          this.prev = e.data.split(';')
      })
      run.catch(e=>{
          console.log('err')
      });
      var run2 = axios.post(this.link() + 'getclient', {id : this.id})
      await run2.then(e=>{
          this.username = e.data
      })
      run2.catch(e=>{
          console.log('err')
      })
    }
}
</script>
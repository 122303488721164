<template>
    <div class="user">
        <header-comp />
        <div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="home-tab"><users-comp /></div>
  <div class="tab-pane fade" id="clients" role="tabpanel" aria-labelledby="profile-tab"><clients-comp /></div>
  <div class="tab-pane fade" id="tpmateriel" role="tabpanel" aria-labelledby="contact-tab"><tpmateriel-comp /></div>
  <div class="tab-pane fade" id="materiels" role="tabpanel" aria-labelledby="dis-tab"><materiel-comp /></div>
  <div class="tab-pane fade" id="infos" role="tabpanel" aria-labelledby="infos-tab"><info-comp /></div>
</div>
    </div>
</template>

<script>
import headerComp from '../components/headerComp'
import usersComp from '../components/usersComp'
import clientsComp from  '../components/clientsComp'
import materielComp from '../components/materielComp'
import tpmaterielComp from '../components/tpmaterielComp'
import infoComp from '../components/infoComp'
export default {
    components : {
        headerComp,
        usersComp,
        clientsComp,
        materielComp,
        tpmaterielComp,
        infoComp
    }
}
</script>
<template>
    <div class="users default1">
        <div class="card">
            <div class="card-header">
                <button data-toggle="modal" data-target="#addclient" class="btn btn-primary"><ic icon="plus" /></button>
            </div>
            <div class="card-body">
<table class="table table-striped" id="cmds">
  <thead>
    <tr>
      <th scope="col">Nom Complete client</th>
      <th scope="col">Mobile client</th>
      <th scope="col">Email client</th>
      <th scope="col">Pin client</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="(client,index) in clients" :key="index">
          <td>{{client.name_client}}
              <button data-toggle="modal" @click="handle(client)" data-target="#upclient" class="btn btn-success"><ic icon="user-edit" /></button>
              <button data-toggle="modal" @click="handle(client)"  data-target="#delclient" class="btn btn-danger"><ic icon="trash" /></button>
          </td>
          <td>{{client.phone_client}}</td>
          <td>{{client.email_client}}</td>
          <td>{{client.pin_client}}</td>
      </tr>
  </tbody>
</table>                
            </div>
        </div>
        <addclient-modal />
        <updateclient-modal :client="client" />
        <delclient-modal :client="client" />
    </div>
</template>

<script>
import datatable from 'datatables.net-bs4'
import axios from 'axios'
import addclientModal from '../modals/addclientModal'
import updateclientModal from '../modals/updateclientModal'
import delclientModal from '../modals/delclientModal'
export default {
    data : ()=>{
        return{
            clients : [],
            client : []
        }
    },
    components : {
        addclientModal,
        updateclientModal,
        delclientModal
    },
    methods : {
         table()
          {
              this.$nextTick(()=>{
                  $('#cmds').DataTable();
              })
          },
          handle(value)
          {
              this.client = value
          }
    },
    created()
    {
        var run = axios.get(this.link() + 'clients')
        run.then(e=>{
            this.clients = e.data
            this.table()
        })
        run.catch(e=>{
            console.log('err')
        })
    }
}
</script>
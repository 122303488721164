<template>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">Utilisateurs</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="clients-tab" data-toggle="tab" href="#clients" role="tab" aria-controls="clients" aria-selected="false">Clients</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="materiel-tab" data-toggle="tab" href="#tpmateriel" role="tab" aria-controls="materiel" aria-selected="false">Type materiels</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="materiel-tab" data-toggle="tab" href="#materiels" role="tab" aria-controls="materiel" aria-selected="false">Materiels</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="materiel-tab" data-toggle="tab" href="#infos" role="tab" aria-controls="materiel" aria-selected="false">Informations</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="materiel-tab" data-toggle="tab" href="#" role="tab" aria-controls="materiel" aria-selected="false"><button @click="dec" class="btn btn-danger"><ic icon="power-off" /></button></a>
  </li>
</ul>
</template>

<script>
export default {
  methods : {
    dec()
    {
      localStorage.removeItem('muserid')
      localStorage.removeItem('type')
      location.reload()
    }
  }
}
</script>
<template>
    <div class="modal fade" id="upclient" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modifier client</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <label for="upnamec" class="weight">Nom complete</label>
            <input type="text"  id="upnamec" placeholder="Nom complete" class="form-control" v-model="client.name_client" >
        </div>

        <div class="form-group">
            <label for="upphonec" class="weight">Mobile</label>
            <input type="number"  id="upphonec" placeholder="Mobile" class="form-control" v-model="client.phone_client" >
        </div>

        <div class="form-group">
            <label for="upemailc" class="weight">Email</label>
            <input type="email"  id="upemailc" placeholder="Email" class="form-control" v-model="client.email_client" >
        </div>

        <div class="form-group">
            <label for="uppinc" class="weight">Pin</label>
            <input type="number"  id="uppinc" placeholder="Pin" class="form-control" v-model="client.pin_client" >
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" @click="update" class="btn btn-success">Modifier</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props : ['client'],
    methods : {
        update()
        {
            var run = axios.post(this.link() + 'updateclient',this.client)
            run.then(e=>{
                if(e.data == "no")
                {
                    this.statu('Mobile dèja utilisè','error')
                }else if(e.data == "yes")
                {
                    this.statu('Opèration èffectuè','success')
                    location.reload()
                }
            })
            run.catch(e=>{
                console.log('err')
            })
        }
    }
}
</script>
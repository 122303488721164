<template>
    <div  class="users defaulta">
        <h3 class="center">Messages</h3>
        <div class="form-group">
            <label for="message" class="weight">Message</label>
            <textarea id="message" class="form-control" v-model="send.msg" @keyup="checker" placeholder="Message"></textarea>
        </div>
        <div class="form-group">
            <button @click="add" :disabled="dis" class="btn btn-primary form-control"><ic icon="plus" /></button>
        </div>
        <div class="card">
            <div class="card-body">
<table class="table table-striped" id="cmdsss">
  <thead>
    <tr>
        <th scope="col">#</th>
      <th scope="col">Message</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(info,index) in infos" :key="index">
      <td>{{index + 1}}</td>
      <td>{{info}}</td>
      <td><button @click="del(info)" class="btn btn-danger"><ic icon="trash" /></button></td>
    </tr>
  </tbody>
</table>                
            </div>
        </div>
        <h3 class="center">contacts</h3>
        <div class="card">
            <div class="card-body">
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Email</th>
      <th scope="col">Mobile</th>
      <th scope="col">Adresse</th>
    </tr>
  </thead>
  <tbody>
      <td><input type="text" style='width: 100%;background: none;outline: none;border: none;' @change="changeEmail($event)" :value="contacts[0]"></td>
      <td><input type="text" style='width: 100%;background: none;outline: none;border: none;' @change="changePhone($event)" :value="contacts[1]"></td>
      <td><input type="text" style='width: 100%;background: none;outline: none;border: none;' @change="changeAdr($event)" :value="contacts[2]"></td>

  </tbody>
</table>                
            </div>
        </div>
    </div>
</template>

<script>
import datatable from 'datatables.net-bs4'
import axios from 'axios'
export default {
    data : ()=>{
        return{
            infos : [],
            contacts : [],
            send : {
              msg : null,
            },
            dis : true,
        }
    },
    methods : {
         table()
          {
              this.$nextTick(()=>{
                  $('#cmdsss').DataTable();
              })
          },
          table2()
          {
              this.$nextTick(()=>{
                  $('#cmdssss').DataTable();
              })
          },
          add()
          {
            var run = axios.post(this.link() + 'addinfo',this.send)
            run.then(e=>{
              if(e.data == "yes")
              {
                this.statu('Opèration èffectuè','success')
                location.reload()
              }
            })
            run.catch(e=>{
              console.log('err')
            });
          },
          checker()
          {
            this.dis = (this.send.msg == null) ? true : false
          },
          del(value)
          {
            var run = axios.post(this.link() + 'delinfo',{msg : value})
            run.then(e=>{
              if(e.data == "yes")
              {
                this.statu('Opèration èffectuè','success')
                location.reload()
              }
            })
            run.catch(e=>{
              console.log('err')
            });
          },
          changeEmail(event)
          {
            var run = axios.post(this.link() + 'update',{email : event.target.value,prior : "one"})
            run.then(e=>{
              if(e.data == "yes")
              {
                this.statu('Opèration èffectuè','success')
              }
            })
            run.catch(e=>{
              console.log('err')
            });
          },
          changePhone(event)
          {
            var run = axios.post(this.link() + 'update',{phone : event.target.value,prior : "two"})
            run.then(e=>{
              if(e.data == "yes")
              {
                this.statu('Opèration èffectuè','success')
              }
            })
            run.catch(e=>{
              console.log('err')
            });
          },
          changeAdr(event)
          {
            var run = axios.post(this.link() + 'update',{adr : event.target.value,prior : "three"})
            run.then(e=>{
              if(e.data == "yes")
              {
                this.statu('Opèration èffectuè','success')
              }
            })
            run.catch(e=>{
              console.log('err')
            });
          }
    },
    created()
    {
      var run = axios.get(this.link() + 'infos')
      run.then(e=>{
        this.infos = e.data.split(';')
        this.table()
      })
      run.catch(e=>{
        console.log('err')
      });

      var run2 = axios.get(this.link() + 'contacts')
      run2.then(e=>{
        this.contacts = e.data.split(';')
        this.table2()
      })
      run2.catch(e=>{
        console.log('err')
      });
    }
}
</script>
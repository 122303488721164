<template>
    <div class="home">
       <login-comp v-if="!view" />
       <main-comp v-if="view" :type="type" />
    </div>
</template>

<script>
import loginComp from '../components/loginComp'
import mainComp from '../components/mainComp'
export default {
    data : ()=>{
        return{
            view : null,
            type : null,
        }
    },
    components : {
        loginComp,
        mainComp,
    },
    async created()
    {
        this.view = (localStorage.getItem('muserid') != undefined) ? await true : await false
        this.type = (this.view) ? await localStorage.getItem('type') : await null
    }
}
</script>
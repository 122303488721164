<template>
    <div class="login default">
        <div class="card">
            <div class="card-body">
                <h3 class="center">Connexion</h3>
                <div class="form-group">
                    <label for="phone" class="weight">Mobile</label>
                    <input type="number" id="phone" @keyup="checker" v-model="send.phone" class="form-control" placeholder="Mobile">
                </div>
                <div class="form-group">
                    <label for="pni" class="weight">Pin</label>
                    <input type="number" id="pin" @keyup="checker" v-model="send.pin" class="form-control" placeholder="Pin">
                </div>
                <button :disabled="dis" @click="connect" class="btn btn-primary form-control"><ic icon="lock-open"  /></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data : ()=>{
        return{
            dis : true,
            send : {
                phone : null,
                pin : null
            }
        }
    },
    methods : {
        connect()
        {
            this.dis = true
            var run = axios.post(this.link() + 'auth',this.send)
            run.then(e=>{
                if(e.data == "no")
                {
                    this.statu('Compte inexistant','error')
                    this.dis = false
                }else if(e.data == "nopin")
                {
                    this.statu('Code pin inexistant','error')
                    this.dis = false
                }else{
                    this.statu('Opèration èffectuè','success')
                    if(e.data[0].id_client != undefined)
                    {
                        localStorage.setItem('muserid',e.data[0].id_client)
                        localStorage.setItem('type',0)
                    }else{
                        localStorage.setItem('muserid',e.data[0].id_user)
                        localStorage.setItem('type',1)
                    }
                    location.reload()
                }
            })
            run.catch(e=>{
                console.log('err')
            })
        },
        checker()
        {
            this.dis = (this.send.phone == null || this.send.pin == null) ? true : false
        }
    }

}
</script>
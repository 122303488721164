<template>
    <div class="modal fade" id="timesdevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group">
            <label for="comment1" class="weight">Note</label>
            <textarea v-model="send.comment" @keyup="checker" class="form-control" placeholder="Commentaire" id="comment1"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" :disabled="dis" @click="check" class="btn btn-success"><ic icon="check" /></button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props : ['dv'],
    data : ()=>{
        return{
          dis : true,
            send : {
                id : null,
                comment : null,
            }
        }
    },
    methods : {
        check()
        {
          this.send.id = this.dv.id_device
          axios.post(this.link() + 'notvaliddeivce',this.send)
          .then(e=>{
              if(e.data == "yes")
              {
                  this.statu('Opèration èffectuè','success')
                  location.reload()
              }
          })
          .catch(e=>{
              console.log('err')
          });
        },
        checker()
        {
          this.dis = (this.send.comment == null) ? true : false
        }
    }

}
</script>
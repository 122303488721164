<template>
    <div class="modal fade" id="updevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modifier materiel</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <label for="upmark" class="weight">Marquee</label>
           <input type="text"   id="upmark" placeholder="Marquee" class="form-control" v-model="dv.mark">
        </div>
        <div class="form-group">
            <label for="uptypes" class="weight">Types</label>
           <select  id="uptypes" class="form-control" v-model="dv.id_type">
               <option v-for="(type,index) in types" :key="index" :value="type.id_type">{{type.name_type}}</option>
           </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" @click="update" class="btn btn-success">Modifier</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'
export default {
    props : ['dv'],
    data : ()=>{
        return{
            types : [],
        }
    },
    methods : {
        update()
        {
            axios.post(this.link() + 'updatedevice',this.dv)
            .then(e=>{
                if(e.data == "yes")
                {
                    this.statu('Opèration èffectuè','success')
                    location.reload()
                }
            })
            .catch(e=>{
                console.log('err')
            });
        }
    },
    created()
    {
        axios.get(this.link() + 'types')
       .then(e=>{
           this.types = e.data
       })
       .catch(e=>{
           console.log('err')
       });
    }

}
</script>
<template>
    <div class="modal fade" id="addtp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter type</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <div class="form-group">
           <label for="type" class="weight">Type</label>
           <input type="text"  id="type" @keyup="checker" class="form-control" placeholder="Type" v-model="send.type">
       </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" @click="add" :disabled="dis" class="btn btn-primary">Ajouter</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data : ()=>{
        return{
            dis : true,
            send : {
                type : null,
            }
        }
    },
    methods : {
        checker()
        {
            this.dis = (this.send.type == null) ? true : false
        },
        add()
        {
            axios.post(this.link() + 'addtype',this.send)
            .then(e=>{
                if(e.data == "yes")
                {
                    this.statu('Opèration èffectuè','success')
                    location.reload()
                }
            })
            .catch(e=>{
                console.log('err')
            });
        }
    }
}
</script>
<template>
    <div class="modal fade" id="bonmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Bon details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-for="(t,index) in infos" :key="index" id='ticket' style='display: flex;width: 450px;padding: 20px;flex-direction: column;align-items: center;'>
            <p style='display: flex;flex-direction: column;' class="center"><span class='weight'><h3>IT-SMV INFORMATIQUE</h3></span>
                <span style="font-size: 12px;" class='center'>Maintenance - dèpannage et Rèparation</span>  </p>
                <span style="font-size: 12px;" class='center'>Ordinateur protables/Pcs/Imprimantes/Serveurs/Onduleurs</span>
                <span class='center weight'><h4>ORDRE SORTIE</h4></span>
                <span class='size'> <span>BON N : </span> {{t.ref_bon}}</span>
                <p > <span class='weight'>Client : </span> {{t.name_client}} </p>
                <p > <span class='weight'>Produit : </span> {{t.mark}} </p>
                <p > <span class='weight'>Commentaire : </span>
                 </p>
                 <p><span v-if="t.comment == null">/</span>
                <span v-else>{{t.comment}}</span></p>
                <p v-if="t.price != null" > <span class='weight'>Prix : </span> {{t.price}}.00 DA</p>
                <div style='display: flex;flex-direction: column;' class="weight">
                   <barcode :value="t.ref_bon"></barcode>
                </div>
                <span class="center">{{contacts.split(';')[0]}}</span>
                    <span class='center'>{{contacts.split(';')[1]}}</span>
                    <span class="center">{{contacts.split(';')[2]}}</span>

        </div>
        <button v-print="'#ticket'" class="btn btn-primary form-control"><ic icon="print" /></button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import print from 'vue-print-nb'
import VueBarcode from 'vue-barcode'
export default {
    props : ['infos','contacts'],
    directives : {
        print
    },
    components : {
        'barcode': VueBarcode,
    },
}
</script>